<template>
  <b-modal
    id="edit-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        編輯分站
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- 分站名稱 -->
          <b-col
            cols="12"
            md="8"
          >
            <validation-provider
              #default="{ errors }"
              name="分站名稱"
              rules="required"
            >
              <b-form-group label-for="name">
                <template #label>
                  <label class="mb-0">
                    分站名稱
                    <span class="text-danger">*</span>
                  </label>
                </template>

                <b-form-input
                  id="name"
                  v-model="branchData.name"
                  autofocus
                  :state="errors.length > 0 ? false : null"
                  trim
                  placeholder="請輸入分站名稱"
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- 代號 -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider name="代號">
              <b-form-group label-for="branch">
                <template #label>
                  <label class="mb-0">
                    代號
                    <span class="text-danger">*</span>
                  </label>
                </template>

                <b-form-input
                  id="branch"
                  v-model="branchDataItem.branch"
                  trim
                  disabled
                  placeholder="請輸入分站代號"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- 分站網域 -->
        <validation-provider
          #default="{ errors }"
          name="分站網域"
          rules="required"
        >
          <b-form-group label-for="domain">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <label class="mb-0">
                  網域
                  <span class="text-danger">*</span>
                </label>

                <div
                  v-b-tooltip.hover.v-secondary
                  title="若要更改要連同寶塔設定一起改"
                >
                  <b-img
                    :src="$store.state.app.themeImages.infoImg"
                    width="22"
                    alt="分站網域說明"
                  />
                </div>
              </div>
            </template>

            <b-form-input
              id="domain"
              v-model="branchData.domain"
              :state="errors.length > 0 ? false : null"
              trim
              placeholder="請輸入分站網域"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- <div>
          <label>分站說明</label>
          <b-form-textarea
            v-model="branchData.introduce"
            placeholder="請輸入分站說明"
            :state="branchData.introduce.length <= maxChar ? null : false"
            rows="5"
          />
          <small
            class="textarea-counter-value float-right"
            :class="branchData.introduce.length > maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ branchData.introduce.length }}</span> / {{ maxChar }}
          </small>
        </div> -->

        <div>
          <div class="d-flex align-items-center justify-content-between font-weight-bold">
            <div>
              <feather-icon
                icon="BoxIcon"
                class="mr-50"
                style="margin-top: -3px"
              />

              <span style="font-size: '16px'">驗證設定</span>
            </div>

          </div>

          <hr class="mt-50">

          <div class="px-50">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex justify-content-between flex-row w-100">
                <div class="d-flex justify-content-start">
                  <div>
                    <b-img
                      :src="require('@/assets/images/pages/customer/email.svg')"
                      height="45"
                      rounded
                    />
                  </div>

                  <div class="ml-2">
                    <h5 class="d-flex align-items-center font-weight-bolder mr-25 mb-25">
                      <div class="text-body-heading d-flex align-items-center">
                        信箱驗證
                      </div>
                    </h5>

                    <small>
                      檢查信箱是否可正常接收郵件
                    </small>
                  </div>
                </div>
              </div>

              <div class="d-flex align-items-center justify-content-center">
                <toggle-button
                  v-model="branchData.mail_valid_switch"
                  :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                  :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                  :width="60"
                  :height="24"
                  class="m-25 mb-50"
                  :labels="{checked: '開啟', unchecked: '關閉'}"
                  :sync="true"
                />
              </div>
            </div>

            <hr>

            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex justify-content-between flex-row w-100">
                <div class="d-flex justify-content-start">
                  <div>
                    <b-img
                      :src="require('@/assets/images/pages/customer/security.svg')"
                      height="45"
                      rounded
                    />
                  </div>

                  <div class="ml-2">
                    <h5 class="d-flex align-items-center font-weight-bolder mr-25 mb-25">
                      <div class="text-body-heading d-flex align-items-center">
                        電話驗證
                      </div>
                    </h5>

                    <small>
                      確認手機號碼是否有效
                    </small>
                  </div>
                </div>
              </div>

              <div class="d-flex align-items-center justify-content-center">
                <toggle-button
                  v-model="branchData.phone_valid_switch"
                  :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                  :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                  :width="60"
                  :height="24"
                  class="m-25 mb-50"
                  :labels="{checked: '開啟', unchecked: '關閉'}"
                  :sync="true"
                />
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
// API
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

// UI
import {
  BForm, BFormGroup, BFormInput, VBToggle, BButton, BSpinner, BFormInvalidFeedback, BImg, BRow, BCol, VBTooltip,
  // BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// Component
import formValidation from '@core/comp-functions/forms/form-validation'
import { ToggleButton } from 'vue-js-toggle-button'
import { useBranchList, useBranchSetting } from '../useBranch'
import { useAlert } from '@/libs/mixins/index'
// import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    // BFormTextarea,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
    ToggleButton,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    branchDataItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      missingFields: [],
      maxChar: 255,
    }
  },
  methods: {
    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetBranchData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('edit-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '分站名稱',
        branch: '代號',
        domain: '分站網域',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.branchData[Object.keys(requiredFields)[i]] === null || this.branchData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      // if (this.branchData.introduce.length > this.maxChar) {
      //   this.useAlertToast(false, '分站說明字數超過上限')
      //   return
      // }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      this.isBusy = true

      this.setBranchUpdate({
        branch_id: this.branchDataItem.id,
        data: {
          ...this.branchData,
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          this.$emit('refetch-data')
          this.resetBranchData()
          this.$nextTick(() => {
            this.$bvModal.hide('edit-modal')
          })
          this.isBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },

    // (獲取)資料
    getData() {
      const resolveData = {
        ...this.syncObject(this.blankBranchData, this.branchDataItem),
      }
      this.branchData = resolveData
      this.$bvModal.show('edit-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      syncObject,
    } = useBranchSetting()

    const {
      isBusy,
      setBranchUpdate,
    } = useBranchList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankBranchData = {
      name: null,
      branch: null,
      domain: null,
      // introduce: '',
      mail_valid_switch: false,
      phone_valid_switch: false,
    }

    const branchData = ref(JSON.parse(JSON.stringify(blankBranchData)))

    const resetBranchData = () => {
      branchData.value = JSON.parse(JSON.stringify(blankBranchData))
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetBranchData)

    return {
      isBusy,
      syncObject,

      branchData,
      blankBranchData,
      setBranchUpdate,
      useAlertToast,
      useHttpCodeAlert,

      resetBranchData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
</style>
